import gql from 'graphql-tag';

export const loginWithCredentialsMutation = gql`
  mutation loginWithCredentials($clientId: String!, $username: String!, $password: String!) {
    users {
      loginWithCredentials(clientId: $clientId, username: $username, password: $password) {
        accessToken
        expiresIn
        refreshExpiresIn
        refreshToken
      }
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation refreshToken($clientId: String!, $token: String!) {
    users {
      refreshToken(clientId: $clientId, token: $token) {
        accessToken
        expiresIn
        refreshExpiresIn
        refreshToken
      }
    }
  }
`;