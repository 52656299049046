import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { LogoutUser, AccessDenied } from '../store/auth.actions';
import { AuthState } from '../store/auth.state';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    const token = this.store.selectSnapshot(state => state.auth.token);
    if (token) {
      return this.store.select(AuthState.hasManagerRole).pipe(
        tap(hasManagerRole => {
          if (!hasManagerRole) {
            this.store.dispatch(new AccessDenied());
          }
        }),
      );
    } else {
      this.store.dispatch(new LogoutUser());
      return of(false);
    }
  }
}
