import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDialogData } from '../dialog.interface';
import { TRANSLATIONS } from '../../../i18n/en_GB';

@Component({
  selector: 'cms-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  cancelButtonTitle = TRANSLATIONS.CANCEL;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData,
  ) {
    this.data = {
      ...data,
      confirmationButtonTitle: data.confirmationButtonTitle || TRANSLATIONS.YES,
    };
  }

  close(): void {
    this.dialogRef.close();
  }
}
