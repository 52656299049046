import { State } from '@ngxs/store';
import { TierEnum } from '../models/TierEnum';
import { MarqueeLocationEnum } from '../models/IMarquee';
import { PartnerLandingPageStatusEnum } from '../models/PartnerLandingPageStatusEnum';
import { PartnerLandingPageColourEnum } from '../models/PartnerLandingPageColourEnum';
import { PartnerLandingPageCountryCodeEnum } from '../models/PartnerLandingPageCountryCodeEnum';
import { Injectable } from '@angular/core';

interface DictionariesStateModel {
  tiers: TierEnum[];
  marqueeLocations: MarqueeLocationEnum[];
  statuses: PartnerLandingPageStatusEnum[];
  colourModes: PartnerLandingPageColourEnum[];
  countries: PartnerLandingPageCountryCodeEnum[];
}

@State<DictionariesStateModel>({
  name: 'dictionaries',
  defaults: {
    tiers: [TierEnum.FREEMIUM, TierEnum.PREMIUM],
    marqueeLocations: [MarqueeLocationEnum.WATCH],
    statuses: [PartnerLandingPageStatusEnum.DRAFT, PartnerLandingPageStatusEnum.PUBLIC],
    colourModes: [PartnerLandingPageColourEnum.GOLD_BLACK],
    countries: [PartnerLandingPageCountryCodeEnum.UK, PartnerLandingPageCountryCodeEnum.DE],
  },
})
@Injectable()
export class DictionariesState {}
