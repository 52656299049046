import { IStorageService } from '../models/IStorageService';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService implements IStorageService {
  getItem<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key)) as T;
  }

  setItem(key: string, value: any): void {
    const json = JSON.stringify(value);
    localStorage.setItem(key, json);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
