<div class="confirm-dialog-container">
  <h1 mat-dialog-title class="stylish-header">{{ data.title }}</h1>
  <div mat-dialog-content>
    <p>{{ data.description }}</p>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button *ngIf="!data.hideCancelButton" (click)="close()">
      {{ cancelButtonTitle }}
    </button>
    <button mat-stroked-button [mat-dialog-close]="true" cdkFocusInitial>
      {{ data.confirmationButtonTitle }}
    </button>
  </div>
</div>
