export const STORAGE_KEY_USER_TOKEN = 'user_token';
export const STORAGE_KEY_USER_REFRESH_TOKEN = 'user_refresh_token';

export const DEFAULT_PAGINATION_SIZE_OPTIONS = [50, 75, 100];
export const DEFAULT_PAGINATION_SIZE = DEFAULT_PAGINATION_SIZE_OPTIONS[0];

export const DEFAULT_IMAGE_300 = 'https://via.placeholder.com/300';
export const DEFAULT_IMAGE_150 = 'https://via.placeholder.com/150';
export const DEFAULT_IMAGE_70 = 'https://via.placeholder.com/70';

// tslint:disable:max-line-length
export const DEFAULT_LOADER =
  'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"%3E%3Cpath d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"%3E%3C/path%3E%3C/svg%3E';
