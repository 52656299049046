import { Injectable, NgZone } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastr: ToastrService,
    private ngZone: NgZone,
  ) {}

  success(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => this.toastr.success(message, title, override));
  }

  error(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => this.toastr.error(message, title, override));
  }

  info(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => this.toastr.info(message, title, override));
  }

  warning(message?: string, title?: string, override?: Partial<IndividualConfig>): void {
    this.ngZone.run(() => this.toastr.warning(message, title, override));
  }
}
