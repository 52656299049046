import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Store } from '@ngxs/store';
import { LogoutUser } from '../../../store/auth.actions';

@Component({
  selector: 'cms-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
  @Input() drawer: MatDrawer;

  constructor(private store: Store) {}

  logout() {
    this.store.dispatch(new LogoutUser());
  }
}
