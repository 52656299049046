import { Action, State, StateContext, NgxsOnInit, Selector } from '@ngxs/store';
import { LoginUser, LogoutUser, RefreshToken, AccessDenied } from './auth.actions';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { tap, catchError, map } from 'rxjs/operators';
import { STORAGE_KEY_USER_TOKEN, STORAGE_KEY_USER_REFRESH_TOKEN } from '../constants/constants';
import { StorageService } from '../services/storage.service';
import { Injectable, NgZone } from '@angular/core';
import { throwError } from 'rxjs';
import { TRANSLATIONS } from '../i18n/en_GB';
import { ToastService } from '../services/toast.service';
import { Apollo } from 'apollo-angular';
import { loginWithCredentialsMutation, refreshTokenMutation } from '../graphQL/auth.mutation';
import { environment } from '../../environments/environment';
import { LoginWithCredentialsResponse } from '../interfaces/login-with-credentials-response.interface';
import { RefreshTokenResponse } from '../interfaces/refresh-token-response.interface';

interface AuthStateModel {
  isUserLoggedIn: boolean;
  loading: boolean;
  token: string;
  refreshToken: string;
  refreshTokenInProgress: boolean;
  roles: string[];
}

const AuthStateDefaultFactory = {
  isUserLoggedIn: false,
  loading: false,
  token: '',
  refreshToken: '',
  refreshTokenInProgress: false,
  roles: [],
};

@State<AuthStateModel>({
  name: 'auth',
  defaults: AuthStateDefaultFactory,
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector() static hasManagerRole(state: AuthStateModel): boolean {
    return state.roles.indexOf('manager') > -1;
  }

  constructor(
    private router: Router,
    private storageService: StorageService,
    private ngZone: NgZone,
    private toastService: ToastService,
    private apollo: Apollo,
  ) {}

  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    const token: string = this.storageService.getItem(STORAGE_KEY_USER_TOKEN);
    const refreshToken: string = this.storageService.getItem(STORAGE_KEY_USER_REFRESH_TOKEN);

    if (token && refreshToken) {
      ctx.patchState({
        isUserLoggedIn: true,
        loading: false,
        token,
        refreshToken,
        roles: this.getUserRoles(token),
      });
    } else {
      this.ngZone.run(() => this.router.navigateByUrl('/login'));
    }
  }

  @Action(LoginUser)
  loginUser(ctx: StateContext<AuthStateModel>, action: LoginUser) {
    ctx.patchState({
      loading: true,
    });

    return this.apollo.mutate<LoginWithCredentialsResponse>({
      mutation: loginWithCredentialsMutation,
      variables: {
        clientId: environment.clientId,
        ...action.credentials
      }
    }).pipe(
        map(r => r?.data?.users?.loginWithCredentials),
        tap(({ accessToken, refreshToken }) => {
          this.storageService.setItem(STORAGE_KEY_USER_TOKEN, accessToken);
          this.storageService.setItem(STORAGE_KEY_USER_REFRESH_TOKEN, refreshToken);
          ctx.patchState({
            isUserLoggedIn: true,
            loading: false,
            token: accessToken,
            refreshToken,
            roles: this.getUserRoles(accessToken),
          });
        }),
        catchError(error => {
          const errorMessage = error.graphQLErrors[0]?.message || TRANSLATIONS.LOGIN_ERROR;
          this.toastService.error(errorMessage);
          return throwError(error);
        }),
    );
  }

  @Action(LogoutUser)
  logoutUser(ctx: StateContext<AuthStateModel>) {
    ctx.setState(AuthStateDefaultFactory);
    this.storageService.removeItem(STORAGE_KEY_USER_TOKEN);
    this.storageService.removeItem(STORAGE_KEY_USER_REFRESH_TOKEN);
    this.ngZone.run(() => this.router.navigateByUrl('/login'));
  }

  @Action(AccessDenied)
  accessDenied(ctx: StateContext<AuthStateModel>) {
    ctx.setState(AuthStateDefaultFactory);
    this.storageService.removeItem(STORAGE_KEY_USER_TOKEN);
    this.storageService.removeItem(STORAGE_KEY_USER_REFRESH_TOKEN);
    this.toastService.error('Access Denied');
    this.ngZone.run(() => this.router.navigateByUrl('/login'));
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      refreshTokenInProgress: true,
    });
    const { refreshToken } = ctx.getState();

    return this.apollo.mutate<RefreshTokenResponse>({
      mutation: refreshTokenMutation,
      variables: {
        clientId: environment.clientId,
        token: refreshToken
      }
    }).pipe(
        map(r => r?.data?.users?.refreshToken),
        tap(res => {
          this.storageService.setItem(STORAGE_KEY_USER_TOKEN, res.accessToken);
          this.storageService.setItem(STORAGE_KEY_USER_REFRESH_TOKEN, res.refreshToken);
          ctx.patchState({
            refreshTokenInProgress: false,
            token: res.accessToken,
            refreshToken: res.refreshToken,
            roles: this.getUserRoles(res.accessToken),
          });
        }),
        catchError(error => {
          ctx.setState(AuthStateDefaultFactory);
          this.storageService.removeItem(STORAGE_KEY_USER_TOKEN);
          this.storageService.removeItem(STORAGE_KEY_USER_REFRESH_TOKEN);
          this.ngZone.run(() => this.router.navigateByUrl('/login'));
          return throwError(error);
        }),
    );
  }

  private getUserRoles(token: string): string[] {
    if (!token) {
      return [];
    }

    const JwtHelper = new JwtHelperService();
    const { realm_access = {} } = JwtHelper.decodeToken(token) || {};
    const { roles = [] } = realm_access || {};
    return roles;
  }
}
