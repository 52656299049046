import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { LogoutUser, RefreshToken } from '../store/auth.actions';
import { STORAGE_KEY_USER_TOKEN } from '../constants/constants';
import { StorageService } from '../services/storage.service';
import { UNAUTHORIZED_ERROR_CODE } from '../constants/backend-error-codes';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  constructor(
    public store: Store,
    private storageService: StorageService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.handleRequestError(request, next, error)));
  }

  private handleRequestError(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<HttpEvent<any>> {
    this.refreshTokenInProgress = this.store.selectSnapshot(state => state.auth.refreshTokenInProgress);

    if (request.url.includes(environment.loginUrl)) {
      if (request.url.includes('refresh_token')) {
        this.store.dispatch(new LogoutUser());
      }
      return throwError(error);
    }

    if (error.status !== UNAUTHORIZED_ERROR_CODE) {
      return throwError(error);
    }

    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter(result => result),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(request))),
      );
    } else {
      this.refreshTokenSubject.next(false);

      return this.store.dispatch(new RefreshToken()).pipe(
        switchMap(() => {
          this.refreshTokenSubject.next(true);
          return next.handle(this.addAuthenticationToken(request));
        }),
        catchError(() => {
          return throwError(error);
        }),
      );
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.storageService.getItem(STORAGE_KEY_USER_TOKEN);
    if (!token) {
      return request;
    }
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
