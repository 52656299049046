import { ISidebarItem } from '../models/ISidebarItem';

export const MENU_ITEMS: ISidebarItem[] = [
  {
    type: 'link',
    title: 'Home',
    path: '/',
    exactMatch: true,
    materialIcon: 'home',
    divider: false,
  },
  {
    type: 'label',
    title: 'Channels',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: 'Channels',
    path: '/channels',
    exactMatch: false,
    materialIcon: 'queue_music',
    divider: false,
  },
  {
    type: 'link',
    title: 'Categories',
    path: '/channels-categories',
    exactMatch: false,
    materialIcon: 'library_music',
    divider: false,
  },
  {
    type: 'label',
    title: 'Videos',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: 'Categories',
    path: '/video-categories',
    exactMatch: false,
    materialIcon: 'videocam',
    divider: false,
  },
  {
    type: 'label',
    title: 'Marquees',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: 'Marquees',
    path: '/marquees',
    exactMatch: false,
    materialIcon: 'recent_actors',
    divider: true,
  },
  {
    type: 'label',
    title: 'Artists',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: 'Artists list',
    path: '/artists',
    exactMatch: false,
    materialIcon: 'group',
    divider: false,
  },
  {
    type: 'label',
    title: 'Partner pages',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: 'Partner pages list',
    path: '/partner-pages',
    exactMatch: false,
    materialIcon: 'topic',
    divider: false,
  },
  {
    type: 'label',
    title: 'Reports',
    path: '',
    exactMatch: false,
    materialIcon: null,
    divider: false,
  },
  {
    type: 'link',
    title: '7D Errors',
    path: '/reports/seven-digital',
    exactMatch: false,
    materialIcon: 'bug_report',
    divider: false,
  },
  {
    type: 'link',
    title: 'Playlist export',
    path: '/reports/playlist-export',
    exactMatch: false,
    materialIcon: 'queue_music',
    divider: false,
  },
];
