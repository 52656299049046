<mat-toolbar color="primary">
  <mat-toolbar-row>
    <div>
      <button mat-icon-button [disableRipple]="true" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="logo-link" routerLink="/" title="jazzed">jazzed</a>
    </div>
    <div class="spacer"></div>
    <button mat-stroked-button (click)="logout()">
      <i class="material-icons">
        exit_to_app
      </i>
      Log out
    </button>
  </mat-toolbar-row>
</mat-toolbar>
