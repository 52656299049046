export const environment = {
  production: true,
  authUrl: 'https://auth.dev.jazzed.club',
  apiUrl: 'https://api.dev.jazzed.club',
  clientId: 'cms',
  graphQlServerUrl: 'https://api.dev.jazzed.club/api/graphql',
  loginUrl: 'auth/realms/jazzed/protocol/openid-connect/token',
  webPlayerURL: 'https://player.dev.jazzed.club',
  allowPreview: true,
};
