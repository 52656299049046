import { TierEnum } from './TierEnum';

export enum MarqueeLocationEnum {
  HOME = 'HOME',
  WATCH = 'WATCH',
}

export enum MarqueeTypeEnum {
  CHANNELS = 'CHANNEL',
  ARTICLES = 'ARTICLE',
  VIDEOS = 'VIDEO',
  LIVE_EVENT = 'LIVE_EVENT',
  // PLAYLISTS = 'PLAYLIST',
  // ALBUMS = 'ALBUM'
}

export interface IMarqueeItem {
  imageUrl?: string;
  type?: string;
  id?: number;
  countries: string[];
}

export interface IMarquee {
  location: MarqueeLocationEnum;
  tier: TierEnum;
  startDate: string;
  endDate: string;
  items: IMarqueeItem[];
  id: number;
}
