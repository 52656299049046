import { InjectionToken } from '@angular/core';

export const DateFormat = {
  parse: {
    dateInput: 'input',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const BACKEND_DATE_FORMAT_VALUE = 'YYYY-MM-DDTHH:mm';

export const FRONTEND_DATE_FORMAT_VALUE = 'YYYY-MM-DD';

export const FRONTEND_DATE_FORMAT = new InjectionToken<string>('FrontendDateFormat');
export const BACKEND_DATE_FORMAT = new InjectionToken<string>('BackendDateFormat');
