<mat-nav-list>
  <ng-container *ngFor="let menuItem of menuItems">
    <ng-container [ngSwitch]="menuItem.type">
      <h3 *ngSwitchCase="'label'" mat-subheader>{{ menuItem.title }}</h3>

      <a
        mat-list-item
        *ngSwitchCase="'link'"
        [routerLink]="[menuItem.path]"
        [routerLinkActiveOptions]="{ exact: menuItem.exactMatch }"
        routerLinkActive="active"
      >
        <mat-icon mat-list-icon class="material-icons">
          {{ menuItem.materialIcon }}
        </mat-icon>
        <h4 mat-line>{{ menuItem.title }}</h4>
      </a>
    </ng-container>
  </ng-container>
</mat-nav-list>
