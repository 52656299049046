import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ISidebarItem } from '../../../models/ISidebarItem';
import { MENU_ITEMS } from '../../../constants/menu-items';

@Component({
  selector: 'cms-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  menuItems: ISidebarItem[];

  ngOnInit() {
    this.menuItems = MENU_ITEMS;
  }
}
