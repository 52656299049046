import { Injectable } from '@angular/core';

import { IDialogData } from './dialog.interface';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) {}

  createConfirmationDialog(data: IDialogData): MatDialogRef<ConfirmDialogComponent, any> {
    return this.dialog.open(ConfirmDialogComponent, { data });
  }
}
