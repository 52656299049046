<cms-top-bar [drawer]="drawer"></cms-top-bar>

<mat-drawer-container>
  <mat-drawer #drawer mode="side" opened>
    <cms-sidebar></cms-sidebar>
  </mat-drawer>

  <mat-card>
    <router-outlet></router-outlet>
  </mat-card>
</mat-drawer-container>
