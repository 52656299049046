import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './ui/main-layout/main-layout.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(module => module.HomeModule),
      },
    ],
  },
  {
    path: 'channels',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/channels/channels.module').then(module => module.ChannelsModule),
      },
    ],
  },
  {
    path: 'channels-categories',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/categories/categories.module').then(module => module.CategoriesModule),
      },
    ],
  },
  {
    path: 'video-categories',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/video-categories/video-categories.module').then(module => module.VideoCategoriesModule),
      },
    ],
  },
  {
    path: 'artists',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/artists/artists.module').then(module => module.ArtistsModule),
      },
    ],
  },
  {
    path: 'marquees',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/marquees/marquees.module').then(module => module.MarqueesModule),
      },
    ],
  },
  {
    path: 'partner-pages',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/partner-landing-page/partner-landing-page.module').then(
            module => module.PartnerLandingPageModule,
          ),
      },
    ],
  },
  {
    path: 'reports',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reports/reports.module').then(module => module.ReportsModule),
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
