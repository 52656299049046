import { IUserLoginCredentials } from '../models/IUserLoginCredentials';

export class LoginUser {
  static readonly type = '[Auth] LoginUser';

  constructor(public credentials: IUserLoginCredentials) {}
}

export class LogoutUser {
  static readonly type = '[Auth] LogoutUser';
}

export class RefreshToken {
  static readonly type = '[Auth] RefreshToken';
}

export class AccessDenied {
  static readonly type = '[Auth] AccessDenied';
}
