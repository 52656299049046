import { NgModule } from '@angular/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloLink,
  from,
  InMemoryCache,
  NextLink,
  Operation,
  Observable as ApolloObservable,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { Store, Actions, ofActionCompleted } from '@ngxs/store';
import { of, throwError } from 'rxjs';
import { switchMap, take, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { STORAGE_KEY_USER_TOKEN } from '../constants/constants';
import { NO_TOKEN, TOKEN_EXPIRED } from '../constants/backend-error-codes';
import { StorageService } from '../services/storage.service';
import { LogoutUser, RefreshToken } from '../store/auth.actions';

@NgModule({
  imports: [ApolloModule],
})
export class GraphQLModule {
  private fetchingAccesToken = false;
  private uri = environment.graphQlServerUrl;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private storageService: StorageService,
    private store: Store,
    private actions$: Actions,
  ) {
    const http = httpLink.create({ uri: this.uri });
    const authMiddleware = new ApolloLink((operation, forward) => this.assignToken(operation, forward));
    const errorLink = this.getErrorLink();
    apollo.create({
      link: from([authMiddleware, errorLink.concat(http)]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }

  private getErrorLink(): ApolloLink {
    return onError(({ graphQLErrors = [], operation, forward }) => {
      const tokenExpired = graphQLErrors.find((error: any) => error.errorCode === TOKEN_EXPIRED);

      const noToken = graphQLErrors.find((error: any) => error.errorCode === NO_TOKEN);

      if (noToken) {
        return this.store.dispatch(new LogoutUser()) as any;
      }

      if (tokenExpired) {
        if (!this.fetchingAccesToken) {
          this.fetchingAccesToken = true;
          this.store
            .dispatch(new RefreshToken())
            .pipe(
              take(1),
              finalize(() => (this.fetchingAccesToken = false)),
            )
            .subscribe();
        }

        const actionPromise = this.prepareTokenRefreshPromise();
        return this.prepareApolloResponse(actionPromise).flatMap(() => this.assignToken(operation, forward));
      }
    });
  }

  private assignToken(operation: Operation, forward: NextLink): ApolloObservable<any> {
    const token = this.storageService.getItem(STORAGE_KEY_USER_TOKEN);
    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return forward(operation);
  }

  private prepareTokenRefreshPromise(): Promise<any> {
    return this.actions$
      .pipe(
        ofActionCompleted(RefreshToken),
        take(1),
        switchMap(action => {
          if (action.result.successful) {
            return of(true);
          } else {
            return throwError('Error');
          }
        }),
      )
      .toPromise();
  }

  private prepareApolloResponse(promise: Promise<boolean>): ApolloObservable<any> {
    return new ApolloObservable(subscriber => {
      promise.then(
        value => {
          if (subscriber.closed) {
            return;
          }

          subscriber.next(value);
          subscriber.complete();
        },
        error => subscriber.error(error),
      );
    });
  }
}
