import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './modules/graphql.module';
import { NgxsModule } from '@ngxs/store';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { MainLayoutModule } from './ui/main-layout/main-layout.module';
import { AuthState } from './store/auth.state';
import { DictionariesState } from './store/dictionaries.state';
import { DialogModule } from './ui/dialog/dialog.module';
import { TOASTR_CONFIG } from './constants/toastr-config';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

import {
  DateFormat,
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_FORMAT_VALUE,
  FRONTEND_DATE_FORMAT_VALUE,
  FRONTEND_DATE_FORMAT,
} from './constants/date-formats';
import { QuillModule } from 'ngx-quill';
import { RefreshTokenInterceptor } from './interceptors/refresh-token-interceptor.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    GraphQLModule,
    HttpClientModule,
    MainLayoutModule,
    NgxsModule.forRoot([AuthState, DictionariesState]),
    ToastrModule.forRoot(TOASTR_CONFIG),
    DialogModule,
    MatMomentDateModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat },
    { provide: BACKEND_DATE_FORMAT, useValue: BACKEND_DATE_FORMAT_VALUE },
    { provide: FRONTEND_DATE_FORMAT, useValue: FRONTEND_DATE_FORMAT_VALUE },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
